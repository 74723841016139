<template>
  <div>
    <div
      v-if="
        (page === 'home' &&
          landing.branding.preview_title === 'title') && title_auth && title_auth[landing.current_lang]||
        (page === 'advanced' &&
          landing.advanced.tab === 2 &&
          landing.branding.preview_title === 'title') && title_auth  && title_auth[landing.current_lang]|| page === 'menuStyles'&&
          landing.branding.preview_title === 'title'&& title_auth  && title_auth[landing.current_lang]
      "
      class="canvas__title"
      style="min-height: auto; margin: 15px 10px"
      v-html="htmlData(title_auth[landing.current_lang], 'title')"
    ></div>

    <div
      v-if="
        (page === 'home' &&
          landing.branding.preview_title === 'auth_title') && !!title_no_auth[landing.current_lang]||
        (page === 'advanced' &&
          landing.advanced.tab === 2 &&
          landing.branding.preview_title === 'auth_title') && !!title_no_auth[landing.current_lang]|| landing.branding.preview_title === 'auth_title' && !!title_no_auth[landing.current_lang] && page === 'menuStyles'
      "
      class="canvas__title"
      style="min-height: auto; margin: 15px 10px"
      v-html="htmlData(title_no_auth[landing.current_lang])"
    ></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "TextLogoPreview",
  props: ["page"],
  computed: {
    ...mapState(["landing"]),
    ...mapState('branding',['title_no_auth','title_auth']),
    dateToday: function () {
      return moment().format("DD.MM.YYYY");
    },
  },
  data(){
    return{
    datenow: "",
    }
  },
  mounted(){
    this.timeNow();
  },
  methods: {
    timeNow() {
      this.datenow = moment().format("HH:mm");
      setInterval(() => {
        this.datenow = moment().format("HH:mm");
      }, 1000);
    },
    htmlData(data, type) {
      if(data){
        let result = data;
        result = result.replaceAll(':weather:',this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
            0
        ) + " ℃");
        result = result.replaceAll(':time:',this.datenow);
        result = result.replaceAll(":date:",this.dateToday);
        result = result.replaceAll(":name:","Agent");
        result = result.replaceAll(":surname:","Smith");
        result = result.replaceAll(":birthday:","24.03.1999");
        result = result.replaceAll(":room:","707");
        return result
      }
    },
  },
};
</script>

<style>
</style>
