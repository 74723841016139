var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      (_vm.page === 'home' &&
        _vm.landing.branding.preview_title === 'title') && _vm.title_auth && _vm.title_auth[_vm.landing.current_lang]||
      (_vm.page === 'advanced' &&
        _vm.landing.advanced.tab === 2 &&
        _vm.landing.branding.preview_title === 'title') && _vm.title_auth  && _vm.title_auth[_vm.landing.current_lang]|| _vm.page === 'menuStyles'&&
        _vm.landing.branding.preview_title === 'title'&& _vm.title_auth  && _vm.title_auth[_vm.landing.current_lang]
    )?_c('div',{staticClass:"canvas__title",staticStyle:{"min-height":"auto","margin":"15px 10px"},domProps:{"innerHTML":_vm._s(_vm.htmlData(_vm.title_auth[_vm.landing.current_lang], 'title'))}}):_vm._e(),(
      (_vm.page === 'home' &&
        _vm.landing.branding.preview_title === 'auth_title') && !!_vm.title_no_auth[_vm.landing.current_lang]||
      (_vm.page === 'advanced' &&
        _vm.landing.advanced.tab === 2 &&
        _vm.landing.branding.preview_title === 'auth_title') && !!_vm.title_no_auth[_vm.landing.current_lang]|| _vm.landing.branding.preview_title === 'auth_title' && !!_vm.title_no_auth[_vm.landing.current_lang] && _vm.page === 'menuStyles'
    )?_c('div',{staticClass:"canvas__title",staticStyle:{"min-height":"auto","margin":"15px 10px"},domProps:{"innerHTML":_vm._s(_vm.htmlData(_vm.title_no_auth[_vm.landing.current_lang]))}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }